import logo from './images/logo_full.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Blindly is a new kind of dating app, geared towards deeper connections.
        </p>
        <p>
          We aim to encourage meaningful conversations, consideration and authenticity.
        </p>
      </header>
    </div>
  );
}

export default App;
